<template>
    <div class="inner-container">
        <div v-show="!showForm">
            <!--            фильтр-->
            <div class="filter-container">
                <div class="left-content">
                    <b-dropdown
                        class="filter-dropdown"
                        variant="default"
                        ref="drop"
                    >
                        <template #button-content>
                            <span class="lc"
                            ><i class="icon icon-filter"></i> Фильтр</span
                            ><span class="rc"
                        ><i class="icon icon-keyboard"></i
                        ></span>
                        </template>
                        <div>
                            <div class="top-content">
                                <span>Параметры фильтра</span>
                                <i
                                    class="icon icon-close"
                                    @click="$refs.drop.hide(true)"
                                ></i>
                            </div>
                            <!--------------- Плановый период ------------------->
                            <div class="filter-block">
                                <b-form-group label="Плановый период">
                                    <multiselect
                                        ref="bcPlanYear"
                                        v-model="planPeriod"
                                        track-by="name"
                                        label="name"
                                        :options="years"
                                        :allow-empty="false"
                                        :show-labels="false"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!--------------- Область/район бюджета ------------------->
                            <div class="filter-block">
                                <b-form-group label="Область/район бюджета">
                                    <multiselect
                                        v-if="regionBudgetList.length > 0"
                                        ref="bcRegionBudget"
                                        v-model="selRegionBudget"
                                        track-by="name_ru"
                                        label="name_ru"
                                        :options="regionBudgetList"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!--------------- Вид данных ------------------->
                            <div class="filter-block">
                                <b-form-group label="Вид данных">
                                    <multiselect
                                        ref="bcDataType"
                                        v-model="selDataType"
                                        track-by="name_ru"
                                        label="name_ru"
                                        placeholder="Все"
                                        :options="dataTypeList"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!--------------- Версия бюджета ------------------->
                            <div class="filter-block">
                                <b-form-group label="Версия бюджета">
                                    <multiselect
                                        ref="bcVersion"
                                        v-model="selVersion"
                                        track-by="text"
                                        label="text"
                                        placeholder="Все"
                                        :options="filterVersion"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!--------------- Месторасположение проекта ------------------->
                            <div class="filter-block">
                                <b-form-group label="Месторасположение проекта">
                                    <multiselect
                                        ref="bcRegion"
                                        v-model="selRegion"
                                        track-by="name_ru"
                                        label="name_ru"
                                        placeholder="Все"
                                        :options="regionFilter"
                                        :searchable="true"
                                        :show-labels="false"
                                        @input="changeRegion"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!--------------- Направление ------------------->
                            <div class="filter-block">
                                <b-form-group label="Направление">
                                    <multiselect
                                        ref="bcDirection"
                                        v-if="directionFilter"
                                        v-model="selDirection"
                                        track-by="name_ru"
                                        label="name_ru"
                                        placeholder="Все"
                                        :options="directionFilter"
                                        :searchable="true"
                                        :show-labels="false"
                                        @input="filter"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!--------------- Тип объекта ------------------->
                            <div class="filter-block">
                                <b-form-group label="Тип объекта">
                                    <multiselect
                                        ref="bcObject"
                                        v-if="objectFilter"
                                        v-model="selObject"
                                        track-by="name_ru"
                                        label="name_ru"
                                        placeholder="Все"
                                        :options="objectFilter"
                                        :searchable="true"
                                        :show-labels="false"
                                        @input="filter"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!--------------- Статус ------------------->
                            <div class="filter-block">
                                <b-form-group label="Статус">
                                    <multiselect
                                        ref="bcStatus"
                                        v-if="statusFilter"
                                        v-model="selStatus"
                                        track-by="name_ru"
                                        label="name_ru"
                                        placeholder="Все"
                                        :options="statusFilter"
                                        :searchable="true"
                                        :show-labels="false"
                                        @input="filter"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!--------------- АБП ------------------->
                            <div class="filter-block">
                                <b-form-group label="АБП">
                                    <multiselect
                                        ref="bcAbp"
                                        v-model="selAbp"
                                        track-by="text"
                                        label="text"
                                        placeholder="Все"
                                        :options="abpFilter"
                                        :searchable="true"
                                        :show-labels="false"
                                        @input="filter"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!--------------- Гос.программа ------------------->
                            <div class="filter-block">
                                <b-form-group label="Гос.программа">
                                    <multiselect
                                        ref="bcGp"
                                        v-model="selGp"
                                        track-by="name_ru"
                                        label="name_ru"
                                        placeholder="Все"
                                        :options="gpFilter"
                                        :searchable="true"
                                        :show-labels="false"
                                        @input="filter"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!--------------- Характер расходов ------------------->
                            <div class="filter-block">
                                <b-form-group label="Характер расходов">
                                    <multiselect
                                        ref="bcRadio"
                                        v-model="radio"
                                        track-by="text"
                                        label="text"
                                        placeholder="Все"
                                        :options="radioList"
                                        :searchable="true"
                                        :show-labels="false"
                                        @input="filter"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                        </div>
                    </b-dropdown>
                </div>
            </div>
            <!--        хлебные крошки-->
            <div class="filter-breadcrumb">
                <span
                    class="item-breadcrumb"
                    v-if="!openFilterWindow && planPeriod"
                    @click="openFilterByRef('bcPlanYear')"
                >
                    {{ planPeriod.name }}
                </span>
                <span
                    class="item-breadcrumb"
                    v-if="!openFilterWindow && selRegionBudget"
                    @click="openFilterByRef('bcRegionBudget')"
                >
                    {{ selRegionBudget.name_ru }}
                </span>
                <span
                    class="item-breadcrumb"
                    v-if="!openFilterWindow && selDataType"
                    @click="openFilterByRef('bcDataType')"
                >
                    {{ selDataType.name_ru }}
                </span>
                <span
                    class="item-breadcrumb"
                    v-if="!openFilterWindow && selVersion"
                    @click="openFilterByRef('bcVersion')"
                >
                    {{ selVersion.name_ru }}
                </span>
                <span
                    class="item-breadcrumb"
                    v-if="!openFilterWindow && selRegion"
                    @click="openFilterByRef('bcRegion')"
                >
                    {{ selRegion.name_ru }}
                </span>
                <span
                    class="item-breadcrumb"
                    v-if="!openFilterWindow && selDirection"
                    @click="openFilterByRef('bcDirection')"
                >
                    {{ selDirection.name_ru }}
                </span>
                <span
                    class="item-breadcrumb"
                    v-if="!openFilterWindow && selObject"
                    @click="openFilterByRef('bcObject')"
                >
                    {{ selObject.name_ru }}
                </span>
                <span
                    class="item-breadcrumb"
                    v-if="!openFilterWindow && selStatus"
                    @click="openFilterByRef('bcStatus')"
                >
                    {{ selStatus.name_ru }}
                </span>
                <span
                    class="item-breadcrumb"
                    v-if="!openFilterWindow && selAbp"
                    @click="openFilterByRef('bcAbp')"
                >
                    {{ selAbp.abp }}-АБП
                </span>
                <span
                    class="item-breadcrumb"
                    v-if="!openFilterWindow && selGp"
                    @click="openFilterByRef('bcGp')"
                >
                    {{ selGp.name_ru }}
                </span>
                <span
                    class="item-breadcrumb"
                    v-if="!openFilterWindow && radio"
                    @click="openFilterByRef('bcRadio')"
                >
                    {{ radio.text }}
                </span>
            </div>
            <div class="table-container">
                <b-table
                    :fields="tableFields"
                    :items="showBipList"
                    :striped="true"
                    responsive="true"
                    bordered
                    head-variant="light"
                    sticky-header="true"
                    no-border-collapse
                >
                    <template #thead-top="data">
                        <b-tr>
                            <b-th colspan="8"></b-th>
                            <b-th colspan="4" class="text-center"
                            >Оценка, балл</b-th
                            >
                            <b-th colspan="2"></b-th>
                        </b-tr>
                    </template>

                    <template #head(action)="scope">
                        <b-button @click="openAll()">
                            <i
                                class="icon icon-chevron-circle icon-rotate-180"
                                v-if="open"
                            ></i>
                            <i
                                class="icon icon-chevron-circle"
                                v-if="!open"
                            ></i>
                        </b-button>
                    </template>
                    <template #cell(action)="data">
                        <b-button @click="showDetails(data.item)">
                            <i
                                class="icon icon-chevron-circle icon-rotate-180"
                                v-if="data.item._showDetails"
                            ></i>
                            <i
                                class="icon icon-chevron-circle"
                                v-if="!data.item._showDetails"
                            ></i>
                        </b-button>
                    </template>
                    <template #cell(reg_loc)="data">
                        <div class="text-left">
                            {{ data.item.addDatas.reg_loc }}
                        </div>
                    </template>
                    <template #cell(abp)="data">
                        <div>{{ data.item.addDatas.abp.text }}</div>
                    </template>
                    <template #cell(newProject_txt)="data">
                        <div>{{ data.item.addDatas.newProject_txt }}</div>
                    </template>
                    <template #cell(program)="data">
                        <div>{{ data.item.addDatas.program }}</div>
                    </template>
                    <template #cell(type_obj)="data">
                        <div>{{ data.item.addDatas.type_obj }}</div>
                    </template>
                    <template #cell(nameRu)="data">
                        <div class="text-left">
                            <a href="#" @click="openBip(data.item)">{{
                                    data.value
                                }}</a>
                        </div>
                    </template>
                    <template #cell()="data">
                        <div class="text-left">{{ data.value }}</div>
                    </template>
                    <template #cell(statusItem)="data">
                        <div
                            class="text-bold text-warning"
                            :class="{
                                green: data.item.status === '3',
                                red: data.item.status === '4',
                            }"
                            v-b-modal.modal-test
                        >
                            {{ data.item.addDatas.statusItem.name_ru }}
                        </div>
                    </template>
                    <template #cell(total)="data">
                        <div
                            class="text-bold text-center"
                            style="font-size: 1.1rem"
                            :class="{
                                'text-danger': parseFloat(data.item.total) < 4,
                                'text-warning':
                                    parseFloat(data.item.total) >= 4 &&
                                    parseFloat(data.item.total) < 7,
                                'text-success':
                                    parseFloat(data.item.total) >= 7,
                            }"
                        >
                            {{ data.item.total }}
                        </div>
                    </template>
                    <template #cell(more)>
                        <b-button class="more">
                            <i class="icon icon-more"></i>
                        </b-button>
                    </template>
                    <template #row-details="row">
                        <b-card>
                            <b-table
                                :fields="row.item.details.fields"
                                :items="row.item.details.table"
                                responsive="true"
                                bordered
                                head-variant="light"
                                sticky-header="true"
                                no-border-collapse
                            >
                                <template #thead-top="data">
                                    <b-tr>
                                        <b-th></b-th>
                                        <b-th
                                            v-if="
                                                row.item.details.mapBudget
                                                    .size > 0
                                            "
                                            :colspan="
                                                row.item.details.mapBudget.size
                                            "
                                            class="text-center"
                                        >Бюджетные</b-th
                                        >
                                        <b-th
                                            v-if="
                                                row.item.details.mapImpact
                                                    .size > 0
                                            "
                                            :colspan="
                                                row.item.details.mapImpact.size
                                            "
                                            class="text-center"
                                        >Влияние на ЦИ</b-th
                                        >
                                        <b-th
                                            v-if="
                                                row.item.details.mapNeeds.size >
                                                0
                                            "
                                            :colspan="
                                                row.item.details.mapNeeds.size
                                            "
                                            class="text-center"
                                        >Потребность</b-th
                                        >
                                        <b-th></b-th>
                                    </b-tr>
                                </template>
                                <template #cell(name_ru)="data">
                                    <div class="text-left">
                                        {{ data.value }}
                                    </div>
                                </template>
                                <template #cell()="data">
                                    <div class="text-right">
                                        {{ data.value }}
                                    </div>
                                </template>
                            </b-table>
                        </b-card>
                    </template>
                </b-table>
            </div>
        </div>
        <div v-show="showForm" v-if="bipProjectData">
            <!--     кнопки     -->
            <div class="filter-container">
                <div class="left-content"></div>
                <div class="right-content">
                    <div class="filter-actions">
                        <b-button
                            class="ml-auto"
                            variant="light"
                            @click="showForm = !showForm"
                        >назад к списку</b-button
                        >
                    </div>
                </div>
            </div>
            <bip-card
                :showForm="showForm"
                :location="location"
                :regionList="regionList"
                :bip="bipProjectData"
                :filterBox="filterBox"
                :dataTypeList="dataTypeList"
            >
            </bip-card>
        </div>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
    </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import store from "../../../services/store";
import VueElementLoading from "vue-element-loading";
import { CUR_YEAR } from "@/modules/budget-request/components/js/budgetCurYear";
import BipCard from "@/modules/budget/bip/bip-card.vue";
import {
    BipProjectDataClass,
    makeToast,
    years,
    setActualYear,
    sortByField,
    findItem,
    doRowKey,
    getNumber, padLeadingZeros,
} from "./bip-types";

export default {
    name: "bip-grade",
    components: { BipCard, loading: VueElementLoading },
    props: {
        location: undefined,
        regionBudgetList: [],
        versionList: [],
        tabIndex: {
          type: Number,
          required: true
        }
    },
    data() {
        return {
            tableFields: [
                {
                    key: "action",
                    label: " ",
                    class: "toggle-show",
                },
                {
                    key: "code",
                    label: "Код проекта",
                    sortable: true,
                },
                {
                    key: "reg_loc",
                    label: "Район/Населенный пункт",
                },
                {
                    key: "abp",
                    label: "АБП",
                },
                {
                    key: "newProject_txt",
                    label: "Характер расходов",
                    sortable: true,
                },
                {
                    key: "program",
                    label: "Гос.программа",
                },
                {
                    key: "type_obj",
                    label: "Направление/Тип объекта",
                },
                {
                    key: "nameRu",
                    label: "Наименование проекта",
                    sortable: true,
                },
                {
                    key: "budget",
                    label: "Бюджетные критерии",
                    sortable: true,
                },
                {
                    key: "impact",
                    label: "Влияние на ЦИ",
                    sortable: true,
                },
                {
                    key: "needs",
                    label: "Потребность в проекте",
                    sortable: true,
                },
                {
                    key: "total",
                    label: "Итого",
                    sortable: true,
                },
                {
                    key: "statusItem",
                    label: "Статус",
                },
                {
                    key: "more",
                    label: "",
                },
            ],
            detailFields: [
                {
                    key: "nomer",
                    label: "№",
                },
                {
                    key: "name_ru",
                    label: "Проект",
                },
            ],

            bipProjectData: null,
            selRegionBudget: null,

            dataTypeList: [],
            selDataType: null,

            regionList: [],
            regionFilter: [],
            selRegion: null, // текущий регион

            selVersion: null,

            katos: new Map(),
            selKato: null, // текущий НП

            directionList: null,
            directionFilter: null,
            selDirection: null,

            objectList: null,
            objectFilter: null,
            selObject: null, // текущий тип объекта

            statusList: null,
            statusFilter: null,
            selStatus: null, // текущий статус

            abpList: [],
            abpFilter: [],
            selAbp: null,

            gpList: [],
            gpFilter: [],
            selGp: null,

            radioList: [
                { value: 0, text: "Переходящие проекты" },
                { value: 1, text: "Новые инициативы" },
            ],
            radio: null,

            years: [],
            planPeriod: null,

            bipList: [],
            userAbps: [],

            filterBox: null,
            userUiid: null,
            open: false,
            showForm: false,
            openFilterWindow: false,
            loading: false,
        };
    },

    created() {
        this.$watch("location", () => {
            this.loadRegionList();
        });
        this.$watch("planPeriod", () => {
            this.updateVersionByYR();
        });
        this.$watch("selRegionBudget", () => {
            this.updateVersionByYR();
        });
        this.$watch("selDataType", () => {
            this.updateVersion();
        });
        this.$watch("selVersion", () => {
            this.selRegion = null;
            this.selDirection = null;
            this.selObject = null;
            this.selStatus = null;
            this.selAbp = null;
            this.selGp = null;
            if (this.selVersion) {
                this.loadBipList();
            }
        });
        this.$watch('showForm', (value) => {
            this.filterBox = {
                planPeriod: this.planPeriod,
                dataType: this.selDataType,
                variant: this.selVersion
            }
        });
    },

    async mounted() {
        years(this, this.years);
        setActualYear(this, this.versionList, this.location.region, this.years);
        this.userUiid = store.state.user.sub;

        await this.loadRegionList();
        await this.loadDataTypeList();
        await this.loadDirectionList();
        await this.loadObjectList();
        await this.loadStatusList();
        await this.loadDetailFields();
        await this.loadUserAbps();

        await setTimeout(() => {
            this.selRegionBudget = this.regionBudgetList[0];
            this.selDataType = this.dataTypeList[0];
            this.selVersion = this.versionList[0];
            this.loadBipList();
        }, 3000);
    },

    beforeUpdate() {
        for (const row of this.bipList) {
            if (row._showDetails) {
                row._rowVariant = "info";
            }
        }
    },

    computed: {
        filterVersion() {
            let result = this.versionList.filter(
                (row) =>
                    (row.is_deleted === null || !row.is_deleted) &&
                    row.year === this.planPeriod?.year &&
                    this.selRegionBudget && true && row.region_code === this.selRegionBudget.code &&
                    row.data_type === parseInt(this.selDataType.code)
            );
            result = result.sort(sortByField("date_ueb", "desc"));
            return result;
        }, // список для фильтра по версиям

        showBipList() {
            let result = this.bipList;

            if (this.radio) {
                switch (this.radio.value) {
                    case 0:
                        result = this.bipList.filter(
                            (row) =>
                                row.hasOwnProperty("header") &&
                                parseInt(row.header.year) ===
                                parseInt(this.planPeriod.year) &&
                                row.hasOwnProperty("newProject") &&
                                row.newProject === 0
                        );
                        break;
                    case 1:
                        result = this.bipList.filter(
                            (row) =>
                                row.hasOwnProperty("header") &&
                                parseInt(row.header.year) ===
                                parseInt(this.planPeriod.year) &&
                                (!row.hasOwnProperty("newProject") ||
                                    (row.hasOwnProperty("newProject") &&
                                        row.newProject === 1))
                        );
                        break;
                }
            } else {
                result = this.bipList.filter(
                    (row) =>
                        row.hasOwnProperty("header") &&
                        parseInt(row.header.year) === parseInt(this.planPeriod.year)
                );
            }

            if (this.selVersion) {
                result = result.filter(
                    (row) =>
                        row.variant === this.selVersion.variant_uuid ||
                        row.variant_recipient === this.selVersion.variant_uuid
                );
            }

            if (this.selRegion) {
                result = result.filter(
                    (row) => row?.region === this.selRegion.code
                );
            }

            if (this.selKato) {
                result = result.filter(
                    (row) =>
                        (row?.localityObject !== undefined &&
                            row?.localityObject.code === this.selKato.code) ||
                        row?.locality === this.selKato.code
                );
            }

            if (this.selDirection) {
                result = result.filter(
                    (row) =>
                        parseInt(row?.projectType) ===
                        parseInt(this.selDirection.code)
                );
            }

            if (this.selObject !== null) {
                result = result.filter(
                    (row) =>
                        parseInt(row?.object) === parseInt(this.selObject.code)
                );
            }

            if (this.selStatus !== null) {
                result = result.filter(
                    (row) =>
                        parseInt(row?.status) === parseInt(this.selStatus.code)
                );
            }

            if (this.selAbp) {
                result = result.filter(
                    (row) =>
                        parseInt(row.addDatas.abp.abp) ===
                        parseInt(this.selAbp.abp)
                );
            }

            const gps = [];
            if (this.selGp) {
                for (const bip of result) {
                    for (const gp of bip?.governmentProgram) {
                        if (this.selGp.code === gp.code) {
                            gps.push(bip.id);
                        }
                    }
                }
                result = result.filter((row) => gps.includes(row.id));
            }

            return result;
        },
    },

    methods: {
        async changeRegion() {
            await this.filter();
            if (this.selRegion) {
                this.selRegion.localityList =
                    this.selRegion.localityList.filter((row) =>
                        Array.from(this.katos.keys()).includes(row.code)
                    );
                this.selKato = null;
            }
        },

        async createDetail(item) {
            const details = {};
            this.$set(details, "table", []);
            const row3Fields = [];
            const mapBudget = new Map(); // Бюджетные
            const mapImpact = new Map(); // Влияния
            const mapNeeds = new Map(); // Потребность

            // first row of details - weights
            let weight = 0;
            const row1 = { name_ru: "Вес индикатора" };
            if (item.hasOwnProperty("criteriaIndicators")) {
                for (const [key, value] of Object.entries(
                    item?.criteriaIndicators
                )) {
                    if (
                        key.includes("weight") &&
                        !isNaN(value) &&
                        value.toString().length > 0
                    ) {
                        const fieldName = key.substr(0, key.length - 6); // наименование поля
                        const field = this.detailFields.filter(function (f) {
                            if (f.key === fieldName) {
                                return f;
                            }
                        }); // находим наим.поля из заданного перечня

                        if (
                            field.length > 0 &&
                            ["1", "2", "3", "4"].includes(
                                field[0]?.criteria_type
                            )
                        ) {
                            const val = this.getValue(value, 2); // значение поля
                            this.$set(row1, fieldName, val); // запись в первую строку
                            weight += val; // общая сумма баллов по весу

                            switch (field[0].criteria_type) {
                                case "1":
                                    mapBudget.set(field[0], { weight: val });
                                    break;
                                case "2":
                                    mapImpact.set(field[0], { weight: val });
                                    break;
                                case "3":
                                    mapNeeds.set(field[0], { weight: val });
                                    break;
                                default:
                                    break;
                            }
                            row3Fields.push(fieldName); // ???
                        } else {
                            console.log(item.id, key, fieldName); // поле, не вошедшее в перечень
                        }
                    }
                }
            }
            this.$set(row1, "total", Math.round(weight));

            // second row of details
            let mark = 0; // баллы по проекту
            const row2 = { name_ru: item?.nameRu };
            if (item.hasOwnProperty("calcResult")) {
                for (const [key, value] of Object.entries(item?.calcResult)) {
                    if (key.includes("result")) {
                        const fieldName = key.substr(0, key.length - 7);
                        const field = this.detailFields.filter(function (f) {
                            if (f.key === fieldName) {
                                return f;
                            }
                        });
                        if (
                            field.length > 0 &&
                            ["1", "2", "3", "4"].includes(
                                field[0]?.criteria_type
                            )
                        ) {
                            const val = this.getValue(value);
                            this.$set(row2, fieldName, val);
                            mark += val; // общая сумма баллов по проекту

                            switch (field[0].criteria_type) {
                                case "1":
                                    const budget = mapBudget.get(field[0]);
                                    if (budget !== undefined) {
                                        this.$set(budget, "mark", val);
                                    } else {
                                        mapBudget.set(field[0], { mark: val });
                                    }
                                    item.budget += val;
                                    break;
                                case "2":
                                    const impact = mapImpact.get(field[0]);
                                    if (impact !== undefined) {
                                        this.$set(impact, "mark", val);
                                    } else {
                                        mapImpact.set(field[0], { mark: val });
                                    }
                                    item.impact += val;
                                    break;
                                case "3":
                                    const needs = mapNeeds.get(field[0]);
                                    if (needs !== undefined) {
                                        this.$set(needs, "mark", val);
                                    } else {
                                        mapNeeds.set(field[0], { mark: val });
                                    }
                                    item.needs += val;
                                    break;
                                default:
                                    break;
                            }
                        } else {
                            // console.log(item.id, key, fieldName);
                        }
                    }
                    if (key === "totalResult") {
                        const val = this.getValue(value);
                        this.$set(item, "total", val);
                        this.$set(row2, "total", val);
                    }
                }
            }

            //third row of details
            const row3 = { name_ru: "" };
            if (item.hasOwnProperty("criteriaIndicators")) {
                for (const [key, value] of Object.entries(
                    item?.criteriaIndicators
                )) {
                    if (row3Fields.includes(key)) {
                        this.$set(
                            row3,
                            key,
                            typeof value === "number"
                                ? parseFloat(value.toString()).toFixed(2)
                                : value
                        );
                    }
                }
            }
            this.$set(
                row3,
                "dateStateExamination",
                item?._dateStateExamination
            );
            //заполняем вложенную таблицу
            details.table.push(row1);
            details.table.push(row2);
            details.table.push(row3);

            // формируем перечень полей для отображения
            this.$set(details, "mapBudget", mapBudget);
            this.$set(details, "mapImpact", mapImpact);
            this.$set(details, "mapNeeds", mapNeeds);
            // формируем перечень полей для вложенной таблицы объекта
            const detfields = [
                {
                    key: "name_ru",
                    label: "Проект",
                },
            ];
            for (const key of mapBudget.keys()) {
                detfields.push(key);
            }
            for (const key of mapImpact.keys()) {
                detfields.push(key);
            }
            for (const key of mapNeeds.keys()) {
                detfields.push(key);
            }
            detfields.push({
                key: "total",
                label: "Балл",
            });
            this.$set(details, "fields", detfields);

            // округляем итоговые данные и передаем во внешнюю таблицу
            this.$set(
                item,
                "budget",
                this.getFieldValue(item, "budget", "float")
            );
            this.$set(
                item,
                "impact",
                this.getFieldValue(item, "impact", "float")
            );
            this.$set(
                item,
                "needs",
                this.getFieldValue(item, "needs", "float")
            );
            this.$set(item, "details", details);
        },

        async filter() {
            await this.filterUpdate();
            this.tabIndex === 0 && this.submitReport();
        },

        async filterUpdate() {
            const regionsBudget = new Map();
            const regions = new Map();
            const projects = new Map();
            const objects = new Map();
            const statuses = new Map();
            const abps = new Map();
            const gps = new Map();
            this.katos = new Map();

            for (const row of this.showBipList) {
                regionsBudget.set(row.addDatas.region, row.addDatas.region);
                regions.set(row.region, row.region);
                if (
                    row.hasOwnProperty("localityObject") &&
                    row.localityObject &&
                    row.localityObject !== null
                ) {
                    this.katos.set(
                        row.localityObject.code,
                        row.localityObject.code
                    );
                }
                if (row.locality) {
                    this.katos.set(row.locality, row.locality);
                }
                projects.set(parseInt(row.projectType), row.projectType);
                objects.set(parseInt(row.object), row.object);
                statuses.set(parseInt(row.status), row.status);
                abps.set(parseInt(row.addDatas.abp.abp), row.addDatas.abp.abp);
                for (const gp of row.governmentProgram) {
                    gps.set(gp.code, gp.code);
                }
            }

            this.regionFilter = this.regionList.filter((row) =>
                Array.from(regions.keys()).includes(row.code)
            );
            this.directionFilter = this.directionList.filter((row) =>
                Array.from(projects.keys()).includes(parseInt(row.code))
            );
            this.objectFilter = this.objectList.filter((row) =>
                Array.from(objects.keys()).includes(parseInt(row.code))
            );
            this.statusFilter = this.statusList.filter((row) =>
                Array.from(statuses.keys()).includes(parseInt(row.code))
            );
            this.abpFilter = this.abpList.filter((row) =>
                Array.from(abps.keys()).includes(parseInt(row.abp))
            );
            this.gpFilter = this.gpList.filter((row) =>
                Array.from(gps.keys()).includes(row.code)
            );
        },

        async loadRegionList() {
            if (this.location) {
                let response;
                try {
                    response = await fetch(
                        "/api-py/get-kato-regions/" + this.location.code
                    );
                    response = await response.json();
                } catch (err) {
                    console.log(`Ошибка загрузки регионов: ${err.message}`);
                }

                for (const region of response) {
                    if (region.code.substring(2, 3) !== "0") {
                        try {
                            let locals = await fetch(
                                "/api-py/get-kato-localities/" +
                                region.code.substring(0, 4)
                            );
                            locals = await locals.json();
                            locals.sort(this.sortByField("name_ru"));
                            this.$set(region, "localityList", locals);
                            this.regionList.push(region);
                        } catch (error) {
                            makeToast(
                                this,
                                "danger",
                                "Ошибка запроса loadRegions",
                                error.toString()
                            );
                        }
                    }
                }
                this.regionList.sort(this.sortByField("name_ru"));
            }
            if (this.location && this.regionList.length === 0) {
                await this.loadRegionList();
            }
        }, // загрузка районов по области

        getFieldValue(item, field, type, fix = 1) {
            if (item !== undefined && item !== null) {
                switch (type) {
                    case "string":
                        return item[field];
                    case "int":
                        return parseInt(item[field]);
                    case "float":
                        return parseFloat(parseFloat(item[field]).toFixed(fix));
                }
            } else {
                switch (type) {
                    case "string":
                        return "";
                    case "int":
                        return 0;
                    case "float":
                        return 0;
                }
            }
        },

        getValue(value, fix = 1) {
            if (typeof value === "string") {
                return parseFloat(parseFloat(value).toFixed(fix));
            }
            if (typeof value === "number") {
                return parseFloat(value.toFixed(fix));
            }
        },

        async getKatoList(region) {
            try {
                const response = await fetch(
                    "/api-py/locations-by-region-name/" + region
                );
                return await response.json();
            } catch (error) {
                makeToast(
                    this,
                    "danger",
                    "Ошибка запроса населенных пунктов",
                    error.toString()
                );
            }
            return [];
        },

        async loadBipList() {
            let response = [];
            let serverList = [];
            this.bipList = [];
            try {
                if (this.selVersion) {
                    this.loading = true;

                    response = await fetch("/api-py/bip-forms/" + this.selVersion.variant_uuid
                    );

                    response = await response.json();
                    console.log('Response BipList (bip-grade): ', response.bipList)
                    serverList = await response.bipList;
                    await this.loadFilters(
                        new Map(Object.entries(response.abpMap)),
                        new Map(Object.entries(response.gpMap))
                    );
                } else {
                    makeToast(this, 'warning', 'Внимание!', 'По выбранному фильтру нет актуальных версий!');
                }
            } catch (error) {
                makeToast(this, "danger", "Ошибка загрузки serverList", error.toString());
            } finally {
                this.loading = false;
            }

            await setTimeout(() => {
                for (const bip of serverList) {
                    try {
                        const bipObject = BipProjectDataClass.fromJSON(bip);
                        if (!bipObject?.isDeleted) {
                            this.$set(bipObject, "addDatas", {});
                            this.$set(bipObject.addDatas, "abp", {
                                abp: null,
                                text: "",
                            });
                            // region/locality
                            try {
                                const reg = findItem(
                                    bipObject?.region,
                                    "code",
                                    this.regionList
                                );
                                const loc =
                                    bipObject?.localityObject &&
                                    bipObject?.localityObject !== null
                                        ? bipObject?.localityObject
                                        : findItem(
                                        bipObject?.locality,
                                        "code",
                                        reg.localityList
                                        );
                                this.$set(
                                    bipObject.addDatas,
                                    "reg_loc",
                                    reg.name_ru + "/ " + loc.name_ru
                                );
                            } catch (error) {
                                console.log(
                                    "err(reg_loc) =>",
                                    bipObject.id,
                                    bipObject?.region,
                                    bipObject?.locality,
                                    bipObject?.localityObject,
                                    error
                                );
                            }
                            // project type/object
                            try {
                                const project = findItem(
                                    bipObject?.projectType,
                                    "code",
                                    this.directionList
                                );
                                const object = findItem(
                                    bipObject?.object,
                                    "code",
                                    this.objectList
                                );
                                this.$set(
                                    bipObject.addDatas,
                                    "type_obj",
                                    project.name_ru + "/ " + object.name_ru
                                );
                            } catch (error) {
                                console.log(
                                    "err(type_obj) =>",
                                    bipObject?.projectType,
                                    bipObject?.object,
                                    error
                                );
                            }
                            // governmentProgram
                            try {
                                if (
                                    typeof bipObject?.governmentProgram ===
                                    "string"
                                ) {
                                    const gpEl = findItem(
                                        bipObject?.governmentProgram,
                                        "code",
                                        this.gpList
                                    );
                                    console.log('gpEl (bip-grade):', gpEl)
                                    this.$set(
                                        bipObject,
                                        "governmentProgram",
                                        []
                                    );
                                    bipObject.governmentProgram.push(gpEl);
                                }
                                let str = "";
                                for (const gp of bipObject?.governmentProgram) {
                                    if (str.length === 0) {
                                        str = gp.name_ru;
                                    } else {
                                        str = str + ", " + gp.name_ru;
                                    }
                                }
                                this.$set(bipObject.addDatas, "program", str);
                            } catch (error) {
                                console.log(
                                    "err(gos_program) =>",
                                    bipObject?.id,
                                    error
                                );
                            }
                            // newProject
                            try {
                                this.$set(
                                    bipObject.addDatas,
                                    "newProject_txt",
                                    ""
                                );
                                if (bipObject.hasOwnProperty("newProject")) {
                                    const np = findItem(
                                        bipObject?.newProject,
                                        "value",
                                        this.radioList
                                    );
                                    if (!np.hasOwnProperty("code")) {
                                        this.$set(
                                            bipObject.addDatas,
                                            "newProject_txt",
                                            np.text
                                        );
                                    }
                                }
                            } catch (error) {
                                console.log(
                                    "err(newProject) =>",
                                    bipObject?.id,
                                    error
                                );
                            }
                            // статус - наименование по коду справочника
                            try {
                                const stat = findItem(
                                    bipObject?.status,
                                    "code",
                                    this.statusList
                                );
                                this.$set(
                                    bipObject.addDatas,
                                    "statusItem",
                                    stat
                                );
                            } catch (error) {
                                console.log(
                                    "err(status) =>",
                                    bipObject?.id,
                                    error
                                );
                            }
                            // addDatas
                            try {
                                this.$set(bipObject.addDatas, "finace", []);
                                // инициатор
                                if (
                                    bipObject.variant ===
                                    this.selVersion.variant_uuid
                                ) {
                                    this.$set(
                                        bipObject.addDatas,
                                        "region",
                                        bipObject.region_budget
                                    );
                                    if (bipObject?.header?.abp) {
                                        const abp = findItem(
                                            bipObject?.header?.abp,
                                            "abp",
                                            this.abpList
                                        );
                                        if (!abp.hasOwnProperty("code")) {
                                            this.$set(
                                                bipObject.addDatas,
                                                "abp",
                                                abp
                                            );
                                            const finace = [];
                                            bipObject?.newFinace.sort(
                                                sortByField("ppr")
                                            );
                                            for (const fin of bipObject?.newFinace) {
                                                const newCost = {
                                                    gr: fin.gr,
                                                    pgr: fin.pgr,
                                                    abp: bipObject?.header?.abp,
                                                    prg: fin.prg,
                                                    ppr: fin.ppr,
                                                    spf: fin.spf,
                                                };
                                                this.$set(
                                                    newCost,
                                                    "fact1",
                                                    getNumber(
                                                        fin[this.planPeriod.year]
                                                    )
                                                );
                                                this.$set(
                                                    newCost,
                                                    "fact2",
                                                    getNumber(
                                                        fin[this.planPeriod.year + 1]
                                                    )
                                                );
                                                this.$set(
                                                    newCost,
                                                    "fact3",
                                                    getNumber(
                                                        fin[this.planPeriod.year + 2]
                                                    )
                                                );
                                                this.$set(
                                                    newCost,
                                                    "ppr_cost",
                                                    this.pprFinans(
                                                        fin,
                                                        bipObject?.period
                                                    )
                                                );

                                                finace.push(newCost);
                                            }
                                            this.$set(
                                                bipObject.addDatas,
                                                "finace",
                                                finace
                                            );
                                        }
                                    }
                                }
                                // получатель
                                if (
                                    bipObject.variant_recipient ===
                                    this.selVersion.variant_uuid
                                ) {
                                    this.$set(
                                        bipObject.addDatas,
                                        "region",
                                        bipObject.district_budget
                                    );
                                    if (bipObject?.header?.regional.abp) {
                                        const abp = findItem(
                                            bipObject?.header?.regional.abp,
                                            "abp",
                                            this.abpList
                                        );
                                        if (!abp.hasOwnProperty("code")) {
                                            this.$set(
                                                bipObject.addDatas,
                                                "abp",
                                                abp
                                            );
                                            const finace = [];
                                            bipObject?.newFinaceRegional.sort(
                                                sortByField("ppr")
                                            );
                                            for (const fin of bipObject?.newFinaceRegional) {
                                                const newCost = {
                                                    gr: fin.gr,
                                                    pgr: fin.pgr,
                                                    abp: bipObject?.header
                                                        ?.regional.abp,
                                                    prg: fin.prg,
                                                    ppr: fin.ppr,
                                                    spf: fin.spf,
                                                };

                                                this.$set(
                                                    newCost,
                                                    "fact1",
                                                    getNumber(
                                                        fin[this.planPeriod.year]
                                                    )
                                                );
                                                this.$set(
                                                    newCost,
                                                    "fact2",
                                                    getNumber(
                                                        fin[this.planPeriod.year + 1]
                                                    )
                                                );
                                                this.$set(
                                                    newCost,
                                                    "fact3",
                                                    getNumber(
                                                        fin[this.planPeriod.year + 2]
                                                    )
                                                );

                                                this.$set(
                                                    newCost,
                                                    "ppr_cost",
                                                    this.pprFinans(
                                                        fin,
                                                        bipObject?.period
                                                    )
                                                );

                                                finace.push(newCost);
                                            }
                                            this.$set(
                                                bipObject.addDatas,
                                                "finace",
                                                finace
                                            );
                                        }
                                    }
                                }
                            } catch (error) {
                                console.log(
                                    "err(finace) =>",
                                    bipObject?.id,
                                    error
                                );
                            }

                            this.$set(bipObject, "_showDetails", false);
                            this.$set(bipObject, "budget", 0);
                            this.$set(bipObject, "impact", 0);
                            this.$set(bipObject, "needs", 0);
                            // createDetail
                            try {
                                this.createDetail(bipObject);
                            } catch (error) {
                                console.log("err(createDetail) =>", bipObject.id, error.toString());
                            }
                            // добавление БИПов в массив
                            this.bipList.push(bipObject);
                        }
                    } catch (error) {
                        console.log(bip.code + ":" + bip.id + error.toString());
                    } finally {
                        this.loading = false;
                    }
                }
                console.log("bip-grade", this.bipList.length);
                this.filter();
            }, 3000);
        },

        async loadDataTypeList() {
            try {
                this.dataTypeList = await fetch(
                    "/api-py/dictionary/budget_data_types/"
                ).then((response) => response.json());
            } catch (error) {
                makeToast(
                    this,
                    "danger",
                    "Ошибка загрузки loadDataTypeList",
                    error.toString()
                );
            }
        },

        async loadDetailFields() {
            try {
                let response = await fetch("/api-py/dictionary/bip_cif_list");
                response = await response.json();

                for (const item of response) {
                    const el = {};
                    this.$set(el, "key", item.code);
                    this.$set(el, "label", item.name_ru);
                    this.$set(el, "criteria_type", item.criteria_type);
                    this.detailFields.push(el);
                }
                this.detailFields.push({
                    key: "total",
                    label: "Балл",
                });
            } catch (error) {
                makeToast(
                    this,
                    "danger",
                    "Ошибка запроса loadDetailFields",
                    error.toString()
                );
            }
        },

        async loadDirectionList() {
            try {
                this.directionList = await fetch(
                    "/api-py/dictionary/bip_project_type_list/"
                ).then((response) => response.json());
            } catch (error) {
                makeToast(
                    this,
                    "danger",
                    "Ошибка загрузки loadDirectionList",
                    error.toString()
                );
            }
        },

        async loadFilters(abpMap, gpMap) {
            try {
                // фильтр АБП
                this.abpList = [];
                for (const [key, value] of abpMap.entries()) {
                    if (value.prgList !== null) {
                        value.prgList.sort(this.sortByField("prg"));
                    }
                    this.abpList.push(value);
                }
                this.abpList.sort(this.sortByField("abp"));

                // фильтр гос.программ
                this.gpList = [];
                for (const [key, value] of gpMap.entries()) {
                    if (key && value) {
                        this.gpList.push(value);
                    }
                }
                this.gpList.sort(this.sortByField("code"));
            } catch (error) {
                makeToast(
                    this,
                    "danger",
                    "Ошибка выполнения loadFilters",
                    error.toString()
                );
            }
        }, // загрузка фильтров АБП, ГП

        async loadObjectList() {
            try {
                this.objectList = await fetch(
                    "/api-py/dictionary/bip_project_object_list/"
                ).then((response) => response.json());
            } catch (error) {
                makeToast(
                    this,
                    "danger",
                    "Ошибка загрузки loadObjectList",
                    error.toString()
                );
            }
        },

        async loadStatusList() {
            try {
                this.statusList = await fetch(
                    "/api-py/dictionary/bip_project_status_list/"
                ).then((response) => response.json());
                this.statusList.sort(sortByField("name_ru"));
            } catch (error) {
                makeToast(
                    this,
                    "danger",
                    "Ошибка загрузки loadStatusList",
                    error.toString()
                );
            }
        },

        async loadServerList() {
            try {
                let response = await fetch("/api-py/bip-forms");
                response = await response.json();
                this.serverList = response.bipList;
                await this.loadFilters(
                    new Map(Object.entries(response.abpMap)),
                    new Map(Object.entries(response.gpMap))
                );
                if (this.regionList.length === 0) {
                    // await this.loadRegionList();
                }
            } catch (error) {
                makeToast(
                    this,
                    "danger",
                    "Ошибка запроса loadServerList",
                    error.toString()
                );
            }
        },

        async loadLocations() {
            for (const region of this.regionList) {
                const katoList = await this.getLocation(region.code);
                const value = {
                    code: region.code,
                    name_ru: region.name_ru,
                    katoList: katoList,
                };
                this.$set(region, "value", value);
            }
        },

        async loadUserAbps() {
            let response = [];
            try {
                this.userUiid = store.state.user.sub;
                if (this.userUiid !== null && this.userUiid.length > 0) {
                    response = await fetch("/api-py/user-abp/" + this.userUiid);
                    response = await response.json();
                    for (const abp of response) {
                        this.userAbps.push(abp.abp);
                    }
                }
            } catch (error) {
                makeToast(
                    this,
                    "danger",
                    "Ошибка загрузки ABP пользователя",
                    error.toString()
                );
            }
        }, // перечень доступных АБП пользователя

        openAll() {
            this.open = !this.open;
            for (const row of this.bipList) {
                row._showDetails = !this.open;
                this.showDetails(row);
            }
        },

        openBip(item) {
            this.bipProjectData = item;
            setTimeout(() => {
                this.showForm = true;
            }, 500);
        },

        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        },

        pprFinans(fin, period) {
            let sum = 0;
            for (const [key, value] of Object.entries(fin)) {
                const y = parseInt(key);
                if (
                    typeof y === "number" &&
                    period._begYear <= y &&
                    y <= period._endYear
                ) {
                    sum += getNumber(value);
                }
            }
            return sum;
        }, // итого суммы по newFinace

        showDetails(row) {
            row._showDetails = !row._showDetails;
            if (row._showDetails) {
                row._rowVariant = "info";
            } else {
                row._rowVariant = "";
            }
        },

        sortByField(field) {
            return (a, b) => (a[field] > b[field] ? 1 : -1);
        }, // сортирует по заданному полю

        submitReport() {
            let more_7 = 0;
            let between_4and7 = 0;
            let less_4 = 0;
            const datas = [];
            for (const bip of this.showBipList) {
                bip.total = bip.total ? bip.total : 0;
                const finList = this.setFinList(bip.addDatas.finace);
                const item = {
                    code: bip.code,
                    reg_loc: bip.addDatas.reg_loc,
                    abp: bip.addDatas.abp.abp,
                    newProject_txt: bip.addDatas.newProject_txt,
                    program: bip.addDatas.program,
                    type_obj: bip.addDatas.type_obj,
                    nameRu: bip.nameRu,
                    budget: bip.budget,
                    impact: bip.impact,
                    needs: bip.needs,
                    total: bip.total,
                    totalCost: bip.totalCost,
                    statusItem: bip.addDatas.statusItem.name_ru,
                    dataList: finList
                };
                datas.push(item);

                if (parseFloat(bip.total) < 4) {
                    less_4++;
                }
                if (parseFloat(bip.total) >= 4 && parseFloat(bip.total) < 7) {
                    between_4and7++;
                }
                if (parseFloat(bip.total) >= 7) {
                    more_7++;
                }
            }

            // объект для передачи в отчет
            const params = {
                year: this.planPeriod.year,
                planPeriod: this.planPeriod,
                selRegionBudget: this.selRegionBudget,
                selDataType: this.selDataType,
                selVersion: this.selVersion,
                selRegion: this.selRegion,
                selProject: this.selDirection,
                selObject: this.selObject,
                selStatus: this.selStatus,
                selAbp: this.selAbp,
                selGp: this.selGp,
                radio: this.radio,
                more_7: more_7,
                between_4and7: between_4and7,
                less_4: less_4,
                datas: datas,
            };
            console.log(this.selRegionBudget, this.selAbp);
            this.$emit("submitReport", params);
        }, // отчет

        setFinList(finace) {
            const datas = [];
            const mapPrg = new Map();
            for (const fin of finace) {
                let curFin = undefined;
                if (this.selPrg) {
                    if (fin.prg === this.selPrg.prg) {
                        if (this.selPpr) {
                            if (fin.ppr === this.selPpr.ppr) {
                                curFin = fin;
                            }
                        } else {
                            curFin = fin;
                        }
                    }
                } else {
                    curFin = fin;
                }
                if (curFin) {
                    const item = {};
                    this.$set(item, 'prg', padLeadingZeros(curFin.prg, 3));
                    this.$set(item, 'ppr', padLeadingZeros(curFin.ppr, 3));
                    this.$set(item, 'spf', padLeadingZeros(curFin.spf, 3));
                    this.$set(item, 'pprCost', curFin.ppr_cost);
                    this.$set(item, 'fact1', curFin.fact1);
                    this.$set(item, 'fact2', curFin.fact2);
                    this.$set(item, 'fact3', curFin.fact3);
                    this.$set(item, 'plan1', curFin.plan1);
                    this.$set(item, 'plan2', curFin.plan2);
                    this.$set(item, 'plan3', curFin.plan3);
                    datas.push(item);
                }
            }
            return datas;
        },

        async updateVersionByYR() {
            let vers = this.versionList.filter(
                (row) =>
                    row.is_deleted === null ||
                    (!row.is_deleted &&
                        row.year === this.planPeriod.year &&
                        this.selRegionBudget &&
                        this.selRegionBudget !== null &&
                        row.region_code === this.selRegionBudget.code)
            );
            vers = vers.sort(sortByField("date_ueb", "desc"));

            for (const ver of vers) {
                if (ver.attribute) {
                    this.selVersion = ver;
                    console.log("TEST: ", this.selVersion.data_type);
                    console.log(this.dataTypeList);
                    this.selDataType = findItem(
                        this.selVersion.data_type,
                        "code",
                        this.dataTypeList
                    );
                    break;
                }
            }

            if (!this.filterVersion.includes(this.selVersion)) {
                this.selVersion = this.filterVersion[0];
            }
        },

        async updateVersion() {
            for (const ver of this.filterVersion) {
                if (ver.attribute) {
                    this.selVersion = ver;
                    break;
                }
            }
            if (!this.filterVersion.includes(this.selVersion)) {
                this.selVersion = this.filterVersion[0];
            }
        }, // обновление версии в зависимости региона и dataType
    },
};
</script>
